let showMenu
const userInMemory = window.localStorage.getItem('killbills_bank_app_user');
if (userInMemory)
    showMenu = JSON.parse(userInMemory).permissions.includes('admin')

const routes=[
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: "/reset/:token", screen: 'reset', secured: false},
    {path: '/', exact: false, screen: 'app', routes: [
        {path: '/', screen: 'home', exact: true},
        {path: '/', exact: false, screen: 'crud_app', routes: [
                {path: "/searchtext", screen: 'search_text'},
                {path: "/transactions/:id", screen: 'transaction'},
                {path: "/transactions", screen: 'transactions'},
                {path: "/matches/:id", screen: 'match'},
                {path: "/matches", screen: 'matches'},
                {path: "/consumers/:id/edit", screen: 'edit_consumer'},
                {path: "/consumers/:id", screen: 'consumer'},
                {path: "/consumer/new", screen: 'create_consumer'},
                {path: "/consumers", screen: 'consumers'},
                {path: "/payloads/:id", screen: 'payload'},
                {path: "/payloads", screen: 'payloads'},
                {path: "/user/profile/edit", screen: 'edit_current_user'},
                {path: "/user/profile/edit_password", screen: 'edit_current_user_password'},
                {path: "/user/profile", screen: 'user_profile'},
                ...(showMenu?[{path: "/users", screen: 'users', exact: true}]:[]),
                {path: '/', screen: 'home', exact: true},
                {screen: 'not_found', exact: false, secured: false},
            ]},
        ]},
];
export default routes