import {BaseApp} from '@genstackio/react-admin-core';
import * as config from './configs';
import useApp from "@genstackio/react-admin-core/lib/hooks/useApp";
import ReactGA from 'react-ga'
import {Helmet} from "react-helmet";

const TRACKING_ID = 'UA-3349852161-5'
ReactGA.initialize(TRACKING_ID)

 const App = () => {
     return (
         <>
          <Helmet>
                 <script
                     type="text/javascript"
                     id="hs-script-loader"
                     defer
                     src="//js-eu1.hs-scripts.com/26223677.js"
                 ></script>
             </Helmet>

             <BaseApp {...useApp(config)} />
         </>
     )}
export default App