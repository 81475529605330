import { breadcrumb_item } from "@genstackio/react-admin-ui/lib/types";

export function breadcrumbs(name: string, {t, id}: {t: Function, history: any, id?: string}): breadcrumb_item[] {
    const items = [{label: t('screen_home_label'), target: '/'}];

    switch (name) {
        case 'current_Bank_Transactions': return items.concat([
            {label: t('screen_transactions_label'), target: '/transactions'},
        ])
        case 'transaction': return items.concat([
            {label: t('screen_transaction_label', {id: id}), target: `/transactions/${id}`},
        ])
        case 'current_Bank_Matches': return items.concat([
            {label: t('screen_receipts_label'), target: '/matches'},
        ])
        case 'match': return items.concat([
            {label: t('screen_receipt_label', {id: id}), target: `/matches/${id}`},
        ])
        case 'payloads': return items.concat([
            {label: t('screen_payloads_label'), target: 'payloads'},
        ])
        case 'payload': return items.concat([
            {label: t('screen_partner_payload_label', {id: id}), target: `/payloads/${id}`},
        ])
        case 'current_Bank_Consumers': return items.concat([
            {label: t('screen_consumers_label'), target: '/consumers'},
        ])
        case 'consumer': return items.concat([
            {label: t('screen_consumer_label', {id: id}), target: `/consumers/${id}`},
        ])
        default: return [
            {label: t('screen_home_label'), target: '/'},
        ];
    }
}

export default breadcrumbs;