// noinspection JSUnusedGlobalSymbols
export const MAPPING_BANK= (gql: any) => gql`
    query mapping {
        mappingBanks {
            properties
        }
    }
`;
export const CREATE_PASSWORD_RECOVERY= (gql: any) => gql`
    mutation createPasswordRecovery($data: CreatePasswordRecoveryInput!) {
        createPasswordRecovery(data: $data) {
            id
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const SEARCH_MEMBERSHIPS = (gql: any) => gql`
    query findMemberships($offset: String, $limit: Int) {
        findMemberships(offset: $offset, limit: $limit) {
        items {
           id organization role user userEmail userLastName userFirstName organizationName
        }
    }
}
`;
// noinspection JSUnusedGlobalSymbols
export const TOKEN_DECODE= (gql: any) => gql`
    query tokenDecode($token: String!) {
        tokenDecode(token: $token) {
            valid
            remainingSeconds
            userId
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const UPDATE_PASSWORD_WITH_TOKEN= (gql: any) => gql`
    mutation updatePasswordWithToken($token: String!, $password: String!) {
        updatePasswordWithToken(token: $token, password: $password) {
            updated
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_PAYLOADS = (gql: any) => gql`
    query findTransactionsForBank($bankId: String, $offset: String, $limit: Int, $query: SearchQueryInput) {
        findTransactionsForBank(bankId: $bankId,offset: $offset, limit: $limit, query: $query) {
            count
            cursor
            items {
                id
                partner
                payload
                dbId
                date
                status
                report
                receiptId
                transactionId
            }
        }
    }
`;
export const GET_PAYLOAD = (gql: any) => gql`
    query getPayload($id: ID!) {
        getPayload(id: $id) {
            id
            partner
            dbId
            payload
            date
            status
            report
            receiptId
            transactionId
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_TRANSACTION= (gql: any) => gql`
    query mapping {
        mappingTransactions {
            properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_MATCH= (gql: any) => gql`
    query mapping {
        mappingMatchs {
            properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_PAYLOAD = (gql: any) => gql`
    query mapping {
        mappingPayloads {
            properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAPPING_CONSUMER= (gql: any) => gql`
    query mapping {
        mappingConsumers {
            properties
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const GET_USER = (gql: any) => gql`
    query getUser($id: ID!) {
        getUser(id: $id) {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            tags
            role
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const CREATE_CONSUMER = (gql: any) => gql`
    mutation createConsumer($data: CreateConsumerInput!) {
        createConsumer(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_CONSUMER = (gql: any) => gql`
    mutation updateConsumer($id: ID!, $data: UpdateConsumerInput!) {
        updateConsumer(id: $id, data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_CURRENT_BANK_CONSUMERS = (gql: any) => gql`
    query findCurrentBankConsumers($offset: String, $limit: Int) {
        findCurrentBankConsumers(offset: $offset, limit: $limit) {
            cursor
            cursor
            items {
                id
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_CURRENT_BANK_MATCHES = (gql: any) => gql`
    query findCurrentBankMatches($offset: String, $limit: Int) {
        findCurrentBankMatches(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                bankName
                createdAt
                updatedAt
                status
                transaction
                amount
                receipt
                merchant
                merchantName
                storeName
                storeAddress
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_CURRENT_BANK_TRANSACTIONS = (gql: any) => gql`
    query findCurrentBankTransactions($offset: String, $limit: Int) {
        findCurrentBankTransactions(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                amount
                bankName
                createdAt
                updatedAt
                status
                storeName
                currency
            }
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const GET_MATCH_CLIENT = (gql: any) => gql`
    query getMatchInBankFormat($matchId: ID!) {
        getMatchInBankFormat(matchId: $matchId) {
            data
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_TRANSACTIONS = (gql: any) => gql`
    query searchCurrentBankTransactions($offset: String,$query: SearchQueryInput, $limit: Int, $sort: String) {
        searchCurrentBankTransactions(offset: $offset, query: $query,limit: $limit, sort: $sort) {
            count
            cursor
            items {
                id
                amount
                bankName
                storeName
                consumer
                createdAt
                updatedAt
                status
                currency
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_CURRENT_BANK_USERS = (gql: any) => gql`
    query findCurrentBankUsers($offset: String, $limit: Int) {
        findCurrentBankUsers(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                lastName
                firstName
                email
                createdAt
                updatedAt
                status
            }
        }
    }
`;


// noinspection JSUnusedGlobalSymbols
export const GET_CURRENT_USER = (gql: any) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            lastConnexionDate
            firstName
            lastName
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const GET_CURRENT_BANK = (gql: any) => gql`
    query getCurrentBank {
        getCurrentBank {
            id
            name
            hmac
            statMatches
            statConsumers
            statTransactions
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_CURRENT_USER = (gql: any) => gql`
mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
        id
        firstName
        lastName
        email
        lastConnexionDate
    }
}
`;

export const CREATE_CURRENT_USER_KEY = (gql:any) => gql`
mutation createCurrentUserKey($data: CreateKeyInput!){
	createCurrentUserKey(data: $data){
	id
	}
}
`;

export const FIND_CURRENT_USER_KEY = (gql:any) => gql`
query {
	findCurrentUserKey{
        items{id userMail expirationDate permissions{actions ressource}}
        
	}
}
`;

// noinspection JSUnusedGlobalSymbols
export const LOGIN = (gql: any) => gql`
    mutation login($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const MAKE_STATS = (gql: any) => gql`
    query compound($dayOne: Float!, $today: Float!, $dayTwo: Float!) {
        dashboard(dayOne:$dayOne today:$today dayTwo:$dayTwo ) 
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_STORES = (gql: any) => gql`
    query findStores($offset: String, $limit: Int) {
        findStores(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                name
                merchant
                merchantName
                externalReference
                createdAt
                updatedAt
                address
                location
                status
                tags
                siret
                statMatches
                statReceipts
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_STORES = (gql: any) => gql`
    query searchStores($offset: String, $limit: Int, $sort: String) {
        searchStores(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                name
                merchantName
                createdAt
                updatedAt
                address
                location
            }
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MINIMAL_SEARCH_STORES = (gql: any) => gql`
    query searchStores($offset: String, $limit: Int, $sort: String) {
        searchStores(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                
                createdAt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_PUBLIC_STORE = (gql: any) => gql`
    query findPublicStore($offset: String, $limit: Int) {
        findPublicStore(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                name
                merchant
                merchantName
                externalReference
                createdAt
                updatedAt
                address
                location
                status
                tags
                siret
                statMatches
                statReceipts
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_STORE = (gql: any) => gql`
    query getStore($id: ID!) {
        getStore(id: $id) {
            id
            name
            merchant
            merchantName
            externalReference
            createdAt
            updatedAt
            address
            location
            status
            tags
            siret
            paymentName
            headerTicket
            footerTicket
            statReceipts
            statMatches
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_CURRENT_BANK_CONSUMERS = (gql: any) => gql`
    query searchCurrentBankConsumers($offset: String, $limit: Int) {
        searchCurrentBankConsumers(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                name
                createdAt
                updatedAt
                bank
                bankName
                externalId
                statTransactions
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_CONSUMER = (gql: any) => gql`
    query getConsumer($id: ID!) {
        getConsumer(id: $id) {
            id
            name
            createdAt
            updatedAt
            bank
            bankName
            externalId
            statTransactions
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_CURRENT_BANK_MATCHES = (gql: any) => gql`
    query searchCurrentBankMatches($offset: String, $limit: Int, $sort: String) {
        searchCurrentBankMatches(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            items {
                id
                createdAt
                updatedAt
                bank
                bankName
                storeName
                storeAddress
                transaction
                StoreLegitNumber
                transactionExtRef
                reference
                amount
                merchant
                merchantName
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_MATCHS = (gql: any) => gql`
    query searchCurrentBankMatches($offset: String,$query: SearchQueryInput, $limit: Int, $sort: String) {
        searchCurrentBankMatches(offset: $offset, query: $query, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                reference
                bankName
                bank
                merchant
                merchantName
                storeName
                createdAt
                updatedAt
                status
                amount
                transaction
                receipt
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_MATCH = (gql: any) => gql`
    query getMatch($id: ID!) {
        getMatch(id: $id) {
            amount
            bank
            bankName
            createdAt
            id
            merchant
            merchantName
            payment
            receipt
            reference
            status
            StoreLegitNumber
            storeName
            tags
            transaction
            transactionConsumer
            transactionExtRef
            updatedAt
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_CURRENT_BANK_TRANSACTIONS = (gql: any) => gql`
    query searchCurrentBankTransactions($offset: String, $limit: Int, $sort: String) {
        searchCurrentBankTransactions(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            items {
                id
                createdAt
                updatedAt
                bank
                bankName
                consumer
                currency
                receiptFormat
                storeName
                externalReference
                amount
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_TRANSACTION = (gql: any) => gql`
    query getTransaction($id: ID!) {
        getTransaction(id: $id) {
            id
            createdAt
            updatedAt
            bank
            bankName
            payments {
                lastFour
                bin
                scheme
                authCode
                transactionId
                amount
                transactionDate
                transactionExternalId
                paymentName
            }
            consumer
            currency
            receiptFormat
            storeName
            externalReference
            amount
            payloadId
        }
    }
`;
export const IMPERSONATE = (gql: any) => gql`
query impersonate($id: ID!, $orgId: ID!) {
	getImpersonnatedUser(id: $id){
    id
    password
    role
    firstName
    lastName
    token
    email
    refreshToken
  }
  getOrganization(id: $orgId){
    type
  }
}`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_IN_TEXT = (gql: any) => gql`
    query searchInText($index: String ,$field:String,$value:String){
        searchInText(index:$index ,field:$field,value:$value) {
            hits
        }
    }
`;